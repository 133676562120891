import React, { useContext, useEffect, useState } from 'react';
import { useProcessedHomeContent } from '../../hooks/use-processed-home-content';
import { SmashVehicleSelector } from '../../../../components/sections/smash-vehicle-selector/smash-vehicle-selector';
import { DisclaimerContent } from '../../../../services/disclaimers-service/disclaimers-service';
import { VehicleYmmGroup } from '../../../../models/vehicle-ymm';
import useSeoConfig from '../../../../hooks/use-seo-config';
import { enableLanguageLinksforTH } from '../../../../components/utils/syndicate-util/syndicate-util';
import { VehicleListServiceAem } from '../../../../services/vehicle-list-service-aem/vehicle-list-service-aem';
import ServerContext from '../../../../contexts/serverContext';
import { PageType } from '../../../../components/sections/view-template/page-type';
import { PreImagineSmashVehicleSelector } from '../../../../components/sections/pre-imagine-smash-vehicle-selector/pre-imagine-smash-vehicle-selector';

export interface Props {
    title?: any;
    page: PageType;
}
export const VehicleSelectorWrapper = (props: Props) => {
    const disclaimers: DisclaimerContent[] = [];
    const { processedHomeContent } = useProcessedHomeContent(disclaimers);
    const { brand, currentRegionCode, currentLanguageRegionCode } = useContext(
        ServerContext
    );
    const [vehicleDropdownData, setVehicleDropdownData] = useState<
        VehicleYmmGroup[]
    >([]);
    useSeoConfig('home');
    useEffect(() => {
        enableLanguageLinksforTH();
        const service = new VehicleListServiceAem();
        service
            .getVehicles(brand, currentRegionCode, currentLanguageRegionCode)
            .then(setVehicleDropdownData)
            .catch(console.error);
    }, []);
    useSeoConfig('home');
    const CTAVehicleSelector = () =>
        processedHomeContent && vehicleDropdownData.length > 1 ? (
            <SmashVehicleSelector
                page={props.page}
                title={props.title}
                vehicleDropdownData={vehicleDropdownData}
                labels={{
                    vinHeader: processedHomeContent.vinTooltipHeader,
                    vinPlaceholder:
                        processedHomeContent.vehicleSelectorVinInput,
                    yearDropdown:
                        processedHomeContent.vehicleSelectorYearDropdownLabel,
                    modelDropdown:
                        processedHomeContent.vehicleSelectorModelDropdownLabel,
                    submit:
                        processedHomeContent.vehicleSelectorSubmitButtonLabel,
                    header: processedHomeContent.vehicleSelectorModelSubtitle,
                    vehicleSelectorDescription:
                        processedHomeContent.vehicleSelectorDescription,
                    vehicleSelectorDescriptionWithDisclaimer:
                        processedHomeContent.vehicleSelectorDescriptionWithDisclaimer,
                    vehicleSelectorDescAuthState:
                        processedHomeContent.vehicleSelectorDescAuthState,
                    segmentedControlVinButtonLabel:
                        processedHomeContent.segmentedControlVinButtonLabel,
                    segmentedControlYearAndModelButtonLabel:
                        processedHomeContent.segmentedControlYearAndModelButtonLabel,
                    whereDoIFindVinCta: processedHomeContent.whereDoIFindVinCta,
                }}
            />
        ) : (
            <></>
        );
    const PreImagineCTAVehicleSelector = () =>
        processedHomeContent && vehicleDropdownData.length > 1 ? (
            <PreImagineSmashVehicleSelector
                page={props.page}
                title={props.title}
                vehicleDropdownData={vehicleDropdownData}
                labels={{
                    vinHeader: processedHomeContent.vinTooltipHeader,
                    vinPlaceholder:
                        processedHomeContent.vehicleSelectorVinInput,
                    yearDropdown:
                        processedHomeContent.vehicleSelectorYearDropdownLabel,
                    modelDropdown:
                        processedHomeContent.vehicleSelectorModelDropdownLabel,
                    submit:
                        processedHomeContent.vehicleSelectorSubmitButtonLabel,
                    header: processedHomeContent.vehicleSelectorModelSubtitle,
                    vehicleSelectorDescription:
                        processedHomeContent.vehicleSelectorDescription,
                    vehicleSelectorDescriptionWithDisclaimer:
                        processedHomeContent.vehicleSelectorDescriptionWithDisclaimer,
                    vehicleSelectorDescAuthState:
                        processedHomeContent.vehicleSelectorDescAuthState,
                    segmentedControlVinButtonLabel:
                        processedHomeContent.segmentedControlVinButtonLabel,
                    segmentedControlYearAndModelButtonLabel:
                        processedHomeContent.segmentedControlYearAndModelButtonLabel,
                    whereDoIFindVinCta: processedHomeContent.whereDoIFindVinCta,
                }}
            />
        ) : (
            <></>
        );
    return (
        <div className={'search-parent-wrapper'}>
            {props.page != 'Landing PreImagine' && (
                <div className={'vehicle-selector-wrapper'}>
                    <CTAVehicleSelector />
                </div>
            )}
            {props.page == 'Landing PreImagine' && (
                <div className={'vehicle-selector-wrapper'}>
                    <PreImagineCTAVehicleSelector />
                </div>
            )}
        </div>
    );
};
