import React, { ReactNode } from 'react';
import './masthead-preimagine.scss';
import { HyperLink } from '../../../models/experiencefragments/smarttiles';
import { PrimaryButton } from '../index';
import { Heading } from '../heading/heading';
import generateRandomId from '../../utils/generate-random-id/generate-random-id';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { Picture } from '../picture/picture';

interface Props {
    class?: string;
    children?: ReactNode;
    title?: string;
    headline?: string;
    description?: string;
    cta?: HyperLink;
    mobileImg?: string;
    desktopImg?: string;
    dataTestId?: string;
    header?: string;
}

export const MastheadPreImagine = (props: Props) => {
    const { currentCountryCode } = new AppConfigurationService();

    const getHeader = () => {
        if (props?.title && props?.header) {
            return 'h3';
        } else if (props?.title || props?.header) {
            return 'h2';
        } else {
            return 'h1';
        }
    };

    return (
        <div className={'masthead-container'}>
            <div
                className={`masthead-preimagine ${
                    props.class ? props.class : ''
                }`}
                data-testid={props.dataTestId}
            >
                <Picture
                    largeDesktopImagePath={encodeURI(props.desktopImg || '')}
                    desktopImagePath={encodeURI(props.desktopImg || '')}
                    tabletImagePath={encodeURI(props.desktopImg || '')}
                    mobileImagePath={encodeURI(props.mobileImg || '')}
                    imageAltText={''}
                />
                {props.header && (
                    <div className="masthead-banner-text">
                        <Heading className={'masthead-header'} type={'h1'}>
                            {props.header}
                        </Heading>
                    </div>
                )}
            </div>
            <div className={'masthead-text-container'}>
                {props?.title && (
                    <Heading
                        key={generateRandomId()}
                        type={props?.header ? 'h2' : 'h1'}
                        className="masthead__category-title"
                    >
                        {props.title}
                    </Heading>
                )}
                {props?.headline && (
                    <Heading
                        key={generateRandomId()}
                        type={getHeader()}
                        className={`masthead__category-headline ${
                            currentCountryCode === 'vn'
                                ? 'masthead__category-headline-vietnamese'
                                : ''
                        }`}
                    >
                        {props.headline}
                    </Heading>
                )}
                <div className={'masthead-text-description'}>
                    {props.description}
                </div>
                {props?.cta?.title && (
                    <div className="masthead__cta">
                        <PrimaryButton
                            href={props.cta.url}
                            color="light"
                            fill="outline"
                            chevron={false}
                            ariaLabel={props.cta.ariaLabel}
                            target={props.cta.targetBlank ? '_blank' : ''}
                        >
                            {props.cta.title}
                        </PrimaryButton>
                    </div>
                )}
                {props.children}
            </div>
        </div>
    );
};
