import {
    useHomeContent,
    HomeContentProps,
} from '../../home-page/hooks/use-home-content';
import {
    DisclaimersService,
    DisclaimerContent,
} from '../../../services/disclaimers-service/disclaimers-service';
import { LoginButton } from '../../../components/sections/login-button/login-button';
import React from 'react';
import { useFeatureCards } from '../../../components/sections/vehicle-portal/hooks/use-feature-cards';
import { FeatureCardsFragment } from '../../../components/sections/vehicle-portal/services/feature-cards/feature-card-article-service';
import {
    useBbtContent,
    CategoriesProps,
} from '../../../support/components/browse-by-topic/hook/use-bbt-content';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { SmartTilesFragment } from '../../../models/experiencefragments/smarttiles';
import {
    Article,
    AemArticle,
    AemArticles,
    Articles,
} from '../../../models/experiencefragments/category-view';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';

export function useProcessedHomeContent(disclaimers: DisclaimerContent[]) {
    const category = 'landing';

    const homeContent = useHomeContent('landing');
    let processedHomeContent: HomeContentProps | null = null;
    const processCardFields = (card: FMCardProps, fields: any) => {
        return {
            ...card,
            title: fields[0]?.plain,
            titleWithDisclaimer: fields[0]?.node,
            description: fields[1]?.plain,
            descriptionWithDisclaimer: fields[1]?.node,
        };
    };
    if (homeContent) {
        const [
            bannerFields,
            bannerDisclaimers,
        ] = DisclaimersService.processFieldsWithDisclaimers(
            ['bannerTitle', 'bannerDescription'],
            'bannerDisclaimer',
            disclaimers,
            homeContent
        );
        disclaimers = disclaimers.concat(bannerDisclaimers);
        const [
            vehicleSelectorFields,
            vehicleSelectorDisclaimers,
        ] = DisclaimersService.processFieldsWithDisclaimers(
            ['vehicleSelectorDescription'],
            'vehicleSelectorDisclaimer',
            disclaimers,
            homeContent,
            [
                {
                    replace: '%signin%',
                    replaceWith: (
                        <LoginButton
                            page="YMM"
                            fill="outline"
                            chevron={false}
                            dataTestId="login"
                            analyticsEventName="smash-home-signin-onclick-event"
                        />
                    ),
                },
            ]
        );
        processedHomeContent = {
            ...homeContent,
            bannerTitle: bannerFields[0]!.plain,
            bannerTitleWithDisclaimer: bannerFields[0]?.node,
            bannerDescription: bannerFields[1]!.plain,
            bannerDescriptionWithDisclaimer: bannerFields[1]?.node,
            vehicleSelectorDescription: vehicleSelectorFields[0]!.plain,
            vehicleSelectorDescriptionWithDisclaimer:
                vehicleSelectorFields[0]?.node,
        };
        disclaimers = disclaimers.concat(vehicleSelectorDisclaimers);
    }

    const featuredArticlesCarouselContent = useFeatureCards(
        category,
        'featured-articles-carousel',
        'articlescarousel',
        false
    );
    let processedFeaturedArticlesCarouselContent: FeatureCardsFragment | null = null;
    if (featuredArticlesCarouselContent) {
        const [
            processedFields,
            processedDisclaimers,
        ] = DisclaimersService.processFieldsWithDisclaimers(
            ['title', 'subtitle'],
            'disclaimer',
            disclaimers,
            featuredArticlesCarouselContent
        );
        disclaimers = disclaimers.concat(processedDisclaimers);
        {
            const [
                processedCards,
                processedDisclaimers,
            ] = DisclaimersService.processItemsWithDisclaimers(
                ['title', 'description'],
                'disclaimer',
                disclaimers,
                featuredArticlesCarouselContent.cards,
                (card, fields) => processCardFields(card, fields)
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            processedFeaturedArticlesCarouselContent = {
                ...featuredArticlesCarouselContent,
                title: processedFields[0]!.plain,
                titleWithDisclaimer: processedFields[0]?.node,
                subtitle: processedFields[1]!.plain,
                subtitleWithDisclaimer: processedFields[1]?.node,
                cards: processedCards,
            };
        }
    }

    const featuredArticlesBenefitsCarouselContent = useFeatureCards(
        category + '/tab-components',
        'featured-articles-carousel',
        'articlescarousel',
        false
    );
    let processedFeaturedArticlesBenefitsCarouselContent: FeatureCardsFragment | null = null;
    if (featuredArticlesBenefitsCarouselContent) {
        {
            const [
                processedCards,
                processedDisclaimers,
            ] = DisclaimersService.processItemsWithDisclaimers(
                ['title', 'description'],
                'disclaimer',
                disclaimers,
                featuredArticlesBenefitsCarouselContent.cards,
                (card, fields) => processCardFields(card, fields)
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            processedFeaturedArticlesBenefitsCarouselContent = {
                ...featuredArticlesBenefitsCarouselContent,
                cards: processedCards,
            };
        }
    }

    const [smashCard] = useExperienceContent<AemArticle>(
        category,
        'smash-card',
        'article',
        undefined,
        false
    );
    let processedSmashCard: Article | undefined;
    if (smashCard && !smashCard.hide) {
        const [
            processedArticle,
            processedDisclaimers,
        ] = DisclaimersService.processArticle(smashCard, disclaimers);
        processedSmashCard = processedArticle;
        disclaimers = disclaimers.concat(processedDisclaimers);
    }
    const bbtContent = useBbtContent();
    let processedBbtContent: CategoriesProps | null = null;
    if (bbtContent) {
        const [
            processedContent,
            processedDisclaimers,
        ] = DisclaimersService.processItemWithDisclaimers(
            ['title', 'subTitle'],
            'disclaimer',
            disclaimers,
            bbtContent,
            (item, fields) => {
                return {
                    ...item,
                    title: fields[0]!.plain,
                    titleWithDisclaimer: fields[0]?.node,
                    subTitle: fields[1]!.plain,
                    subTitleWithDisclaimer: fields[1]?.node,
                };
            }
        );
        disclaimers = disclaimers.concat(processedDisclaimers);
        processedBbtContent = processedContent;
    }

    const [smartTilesContent] = useExperienceContent<SmartTilesFragment>(
        'landing',
        'smart-tiles',
        'smarttiles',
        undefined,
        false
    );
    let processedSmartTileContent: SmartTilesFragment | null = null;
    if (smartTilesContent) {
        const [
            processedInformationGridFields,
            processedDisclaimers,
        ] = DisclaimersService.processFieldsWithDisclaimers(
            ['informationGridTitle', 'informationGridSubCopy'],
            'informationGridDisclaimer',
            disclaimers,
            smartTilesContent
        );
        disclaimers = disclaimers.concat(processedDisclaimers);
        {
            const [
                processedHeroRowTilesFields,
                processedDisclaimers,
            ] = DisclaimersService.processFieldsWithDisclaimers(
                ['heroRowTilesTitle', 'heroRowTilesSubCopy'],
                'heroRowTilesDisclaimer',
                disclaimers,
                smartTilesContent
            );
            disclaimers = disclaimers.concat(processedDisclaimers);
            processedSmartTileContent = {
                ...smartTilesContent,
                informationGridTitle: processedInformationGridFields[0]?.plain,
                informationGridTitleWithDisclaimer:
                    processedInformationGridFields[0]?.node,
                informationGridSubCopy:
                    processedInformationGridFields[1]?.plain,
                informationGridSubCopyWithDisclaimer:
                    processedInformationGridFields[1]?.node,
                heroRowTilesTitle: processedHeroRowTilesFields[0]?.plain,
                heroRowTilesTitleWithDisclaimer:
                    processedHeroRowTilesFields[0]?.node,
                heroRowTilesSubCopy: processedHeroRowTilesFields[1]?.plain,
                heroRowTilesSubCopyWithDisclaimer:
                    processedHeroRowTilesFields[1]?.node,
            };
        }
    }

    const [articlesTrio1] = useExperienceContent<AemArticles>(
        category,
        'articles-trio-1',
        'articles',
        undefined,
        false
    );
    let processedArticlesTrio1: Articles | undefined;
    if (articlesTrio1 && !articlesTrio1.hide) {
        const [
            processedArticles,
            processedDisclaimers,
        ] = DisclaimersService.processArticles(
            articlesTrio1.articles,
            disclaimers
        );
        processedArticlesTrio1 = {
            ...articlesTrio1,
            articles: processedArticles,
        };
        disclaimers = disclaimers.concat(processedDisclaimers);
    }

    const [componentBox5050] = useExperienceContent<AemArticle>(
        category,
        'component-box-50-50',
        'article',
        undefined,
        false
    );
    let processedComponentBox505: Article | undefined;
    if (componentBox5050 && !componentBox5050.hide) {
        const [
            processedArticle,
            processedDisclaimers,
        ] = DisclaimersService.processArticle(componentBox5050, disclaimers);
        processedComponentBox505 = processedArticle;
        disclaimers = disclaimers.concat(processedDisclaimers);
    }
    const [articlesTrio2] = useExperienceContent<AemArticles>(
        category,
        'articles-trio-2',
        'articles',
        undefined,
        false
    );
    let processedArticlesTrio2: Articles | undefined;
    if (articlesTrio2 && !articlesTrio2.hide) {
        const [
            processedArticles,
            processedDisclaimers,
        ] = DisclaimersService.processArticles(
            articlesTrio2.articles,
            disclaimers
        );
        processedArticlesTrio2 = {
            ...articlesTrio2,
            articles: processedArticles,
        };
        disclaimers = disclaimers.concat(processedDisclaimers);
    }

    return {
        processedHomeContent: processedHomeContent,
        processedFeaturedArticlesCarouselContent: processedFeaturedArticlesCarouselContent,
        processedFeaturedArticlesBenefitsCarouselContent: processedFeaturedArticlesBenefitsCarouselContent,
        processedSmashCard: processedSmashCard,
        processedBbtContent: processedBbtContent,
        processedSmartTilesContent: processedSmartTileContent,
        processedArticlesTrio1: processedArticlesTrio1,
        processedComponentBox5050: processedComponentBox505,
        processedArticlesTrio2: processedArticlesTrio2,
        processedDisclaimers: disclaimers,
    };
}
