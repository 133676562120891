import './information-grid.scss';
import {
    SmartTileContent,
    SmartTilesFragment,
} from '../../../models/experiencefragments/smarttiles';
import React from 'react';
import { FMButton, FMListItem } from '@own/fds-react';
import { useAnalytics } from '../../../hooks/use-analytics';

interface Props {
    smartTileContent: SmartTilesFragment | null;
    analyticsClickEventName?: string;
}

export const InformationGrid = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const { smartTileContent } = props;

    const handleClick = (tile: SmartTileContent) => {
        return () => {
            props.analyticsClickEventName &&
                fireEvents(
                    props.analyticsClickEventName,
                    undefined,
                    {
                        smashHomeClick: {
                            tileName: tile.staticTitle,
                            ctaName: tile.staticCTATitle,
                        },
                    },
                    false
                );
        };
    };

    return (
        smartTileContent && (
            <div className={'information-grid'}>
                <div className={'information-grid-title'}>
                    {smartTileContent.informationGridTitle}
                </div>
                <div className={'information-grid-description'}>
                    {smartTileContent.informationGridSubCopy}
                </div>
                {smartTileContent.cta && smartTileContent.cta.title && (
                    <FMButton
                        type={'primary'}
                        label={smartTileContent.cta.title}
                        href={smartTileContent.cta.url}
                        chevron={'right'}
                        target={
                            smartTileContent.cta.targetBlank ? '_blank' : ''
                        }
                    />
                )}
                <div className={'list-item-wrapper'}>
                    {smartTileContent.smartTiles.map((tile, idx) => {
                        return (
                            <FMListItem
                                key={idx + tile.staticTitle}
                                title={tile.staticTitle}
                                description={tile.staticSubCopy}
                                imgPath={
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    tile.iconPath
                                }
                                type={'standard'}
                                ctaPath={tile.staticCTAPath}
                                showChevron={true}
                                onClick={handleClick(tile)}
                            />
                        );
                    })}
                </div>
            </div>
        )
    );
};
