import React, { ReactNode, useCallback, useEffect } from 'react';

import { VehicleYmmGroup } from '../../../models/vehicle-ymm';
import FDSSegmentedControl from '../smash-vehicle-selector-fds/segmentedControl';
import './smash-vehicle-selector.scss';
import {
    AuthenticationState,
    useAuthentication,
} from '../../../hooks/use-authentication';
import { useHistory } from 'react-router-dom';
import { findByAlias } from '../../../routes';
import { useAnalytics } from '../../../hooks/use-analytics';
import { RedirectData } from '../../../support/components/FMALogin/FMALogin';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { PageType } from '../view-template/page-type';
import YMMSelector from '../vehicle-selector-fds/ymm-selector/ymm-selector';
import VINSelector from '../vehicle-selector-fds/vin-selector/vin-selector';
export interface VehicleSelectorLabels {
    yearDropdown: string;
    modelDropdown: string;
    submit: string;
    header: string;
    vinPlaceholder: string;
    vinHeader?: string;
    dividerText?: string;
    vehicleSelectorTitle?: string;
    vehicleSelectorDescription?: string;
    vehicleSelectorDescriptionWithDisclaimer?: ReactNode;
    vehicleSelectorDescAuthState?: string;
    segmentedControlVinButtonLabel?: string;
    segmentedControlYearAndModelButtonLabel?: string;
    whereDoIFindVinCta?: string;
    vehicleSelectorSubmitButtonLabel?: string;
}

export interface Props {
    labels: VehicleSelectorLabels;
    redirectData?: RedirectData;
    tooltip?: ReactNode;
    vehicleDropdownData?: VehicleYmmGroup[];
    articlesSearch?: boolean;
    ymmDefaultView?: boolean;
    onTabClick?: any;
    onChange?: any;
    onSelectedVehicleChange?: (vehicleAttributes: VehicleAttributes) => void;
    dropDownPlaceholder?: string;
    currentSearchVehicle?: VehicleAttributes;
    disableDashboardRedirect?: boolean;
    title: string;
    page?: PageType;
}

export const SmashVehicleSelector = (props: Props) => {
    const [fireEvent] = useAnalytics();
    const { labels, articlesSearch } = props;
    const history = useHistory();
    const goToDashboard = useCallback(() => {
        const ymmPath = findByAlias('VehicleDashboardView');
        history.push(ymmPath);
    }, [history]);
    useEffect(() => {
        const fdsElement = document.querySelector(
            '.smash-vehicle-selector'
        ) as HTMLElement;
        new FDSSegmentedControl(fdsElement);

        const myVehicleDashboardEle = document.querySelector(
            '.smash-dashboard-title'
        ) as HTMLElement;

        myVehicleDashboardEle
            ?.getElementsByTagName('a')[0]
            ?.addEventListener('click', e => {
                e.preventDefault();
                goToDashboard();
            });
    });
    useEffect(() => {
        const findMyVinLink = document.querySelector(
            '.find-vin-cta'
        ) as HTMLElement;

        findMyVinLink?.addEventListener('click', () => {
            fireEvent(
                'smash-home-help-find-vin-onclick-event',
                undefined,
                undefined,
                false
            );
        });
    }, []);
    const [isAuth, ,] = useAuthentication();
    const includeHeadlineMessage = () => {
        if (
            isAuth === AuthenticationState.Authenticated &&
            props.labels.vehicleSelectorDescAuthState
        ) {
            return (
                <span
                    className="smash-dashboard-title"
                    dangerouslySetInnerHTML={{
                        __html: props.labels.vehicleSelectorDescAuthState,
                    }}
                />
            );
        }
        return (
            props.labels.vehicleSelectorDescriptionWithDisclaimer ||
            props.labels.vehicleSelectorDescription
        );
    };
    return (
        <div className="smash-vehicle-selector">
            {articlesSearch ? (
                <>
                    <h2 className="vehicle-selector-title">{props.title}</h2>
                    <p className="vehicle-selector-description">
                        {props.labels.vehicleSelectorTitle}
                    </p>
                </>
            ) : (
                <div>
                    <div className={'vehicle-selector-header'}>
                        <span>{props.title}</span>
                    </div>
                    <div className="vehicle-selector-subcopy">
                        <span>{includeHeadlineMessage()}</span>
                    </div>
                </div>
            )}
            <div className={'vehicle-selector-container'}>
                <div className={'vin-container'}>
                    <VINSelector
                        page={props.page}
                        vinHeaderLabel={''}
                        whereDoIFindVinCta={labels.whereDoIFindVinCta}
                        redirectData={props.redirectData}
                        vinPlaceholderLabel={''}
                        submitLabel={labels.submit}
                        tooltip={props.tooltip}
                        eventName={
                            articlesSearch
                                ? 'owner-dyf-landing-page-cta'
                                : 'smash-home-search-cta-onclick-event'
                        }
                    />
                </div>
                <div className={'selector-divider'} />
                <div className={'ymm-container'}>
                    <YMMSelector
                        yearDropdownLabel={
                            props.labels.yearDropdown
                                ? props.labels.yearDropdown
                                : ''
                        }
                        modelDropdownLabel={
                            props.labels.modelDropdown
                                ? props.labels.modelDropdown
                                : ''
                        }
                        handleDropDownChange={undefined}
                        {...props}
                        ymmHeaderLabel=""
                        submitLabel={labels.submit}
                        eventName={
                            articlesSearch
                                ? 'owner-dyf-landing-page-cta'
                                : 'smash-home-search-cta-onclick-event'
                        }
                    />
                </div>
            </div>
        </div>
    );
};
