function generateRandomId() {
    if (
        typeof window !== 'undefined' &&
        window.crypto &&
        'getRandomValues' in window.crypto
    ) {
        return '10000000-1000-4000-8000-100000000000'.replace(
            /[018]/g,
            current =>
                (
                    parseInt(current) ^
                    (crypto.getRandomValues(new Uint8Array(1))[0] &
                        (15 >> (parseInt(current) / 4)))
                ).toString(16)
        );
    } else {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
            current
        ) {
            const rand = (Math.random() * 16) | 0,
                value = current == 'x' ? rand : (rand & 0x3) | 0x8;
            return value.toString(16);
        });
    }
}

export default generateRandomId;
